
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import news from "../../assets/news.png"
import NewsCard from './components/NewsCard';

const News = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${news})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "ዜናዎች" : "NEWS"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Discover our latest company news, updates, and insights right here. Whether it's industry trends, policy changes, or valuable tips, we're committed to delivering relevant information that empowers you to make informed decisions."}
                    </p>
                </div>
            </div>
            <div className="py-2 w-4/5 ml-[10%]">
                <div className="">
                    <h1 className="font-medium text-xl pt-2 text-[#661F00] ">
                        {isAmh ? "አዳዲስ ዜናዎች" : "Latest News"}
                    </h1>
                    <p className="my-2 ">
                        Catch up on the latest happenings at NIB insurance. Stay informed with our updates, insights, and news articles.
                    </p>
                </div>

                <section className='flex items-stretch justify-center flex-wrap gap-12 mb-20'>
                    {Array.from({ length: 8 }).map((item, index) => (
                        <NewsCard />
                    ))}
                </section>


            </div>



        </div>
    )
}

export default News
