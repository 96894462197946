import React, { useState } from 'react'
import micro from "../../../assets/micro.png";
import { LangContext } from "../../../context/LangContext";
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import bodyone from "../../../assets/LeftFrame.png";
import bodytwo from "../../../assets/RightFrame.png";


const MicroInsurance = () => {
  const { isAmh } = useContext(LangContext);
  const navigate = useNavigate();


  const [isHoveeredOn, setIsHoveredOn] = useState(1)
  const [servicesDataArr, setServicesDataArr] = useState(null)

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
  };
  const servicesData = useQuery(
    ["servicesDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}home/service/1`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      // enabled: !!token,
      onSuccess: (res) => {
        setServicesDataArr([...res.data.data.reverse()])
      },
    }
  );

  return (
    <div> <div
      style={{
        backgroundImage: `url(${micro})`,
        backgroundPosition: "center",
        width: "100%",
        minHeight: "800px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      <div className="absolute inset-0 " />
      <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
        <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
          {isAmh ? "የድርጅት መዋቅር" : "Micro Insurance"}
        </h1>
        <p className="text-lg font-light text-white w-[40%]">
          {isAmh
            ? "ቤት/ስለ እኛ"
            : "Our Micro Insurance offerings are designed to provide accessible and affordable coverage for every individual and family. Explore how we're making insurance simple and cost-effective, ensuring that everyone can safeguard their future without breaking the bank."}
        </p>
      </div>
    </div>

      <div className="relative">
        <div className="absolute left-0 top-0 bottom-0 h-full z-40 hidden md:flex">
          <img src={bodyone} alt="" className="h-full object-contain " />
        </div>
        <div className="absolute right-0 top-0 bottom-0 h-full z-40 hidden md:flex">
          <img src={bodytwo} alt="" className="h-full object-contain " />
        </div>
        <div className="flex flex-col items-center justify-center">

          <p></p>
        </div>

        <div className="relative p-3 ">
          <div className="max-w-6xl mx-auto flex flex-col py-6">
            <h1 className="font-medium  text-[#661F00] py-1 text-2xl">
              {isAmh ? "ማይክሮ ኢንሹራንስ" : "Micro Insurance"}
            </h1>
            <div className="border border-[#FFB300] w-20"></div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto p-3 grid grid-cols-1 md:grid-cols-4 gap-8 mb-24">
          {servicesDataArr ? (
            servicesDataArr?.map((item, index) => (
              <div
                onMouseEnter={() => {
                  setTimeout(setIsHoveredOn(index), 1000)
                }}
                onMouseLeave={() => setIsHoveredOn(null)}
                key={item?.id}
                className="bg-[#F7FAFC] relative p-3 z-40 rounded-lg shadow-md  flex flex-col items-center justify-evenly border-2 border-white hover:shadow-2xl "
              >
                <h1 className="w-full text-sm font-semiBold text-black border-l-4 border-[#FFB300] px-2 ml-2 mt-6">
                  {isAmh ? item.title?.amharic : item.title?.english}
                </h1>
                <img src={item.service_photo} alt="" className="h-36 mt-2" />


                <h4
                  onClick={() => navigate(`/services/detail/${item.id}`)}
                  className={isHoveeredOn === index ?
                    "cursor-pointer hover:opacity-70  bottom-0  border-2 border-[#FFB300] font-semiBold  px-6 py-1 rounded-full bg-[#FFB300] text-white"
                    : "cursor-pointer hover:opacity-70  bottom-0  border-2 border-[#FFB300] font-semiBold  px-6 py-1 rounded-full text-[#FFB300]"}
                >
                  {isAmh ? "ተጨማሪ ያንብቡ" : "Read more"}
                </h4>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center w-full py-10">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#a27128"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MicroInsurance