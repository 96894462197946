
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import prerequest from "../../assets/prerequest.png"
import prerequest2 from "../../assets/prerequest2.png"
import prerequest3 from "../../assets/prerequest3.png"
import prerequest4 from "../../assets/prerequest_icon.png"
import prerequest5 from "../../assets/24.gif"
import CheckList from '../../components/CheckList';


const PreRequest = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div >
            <div
                style={{
                    backgroundImage: `url(${prerequest})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl ">
                        {isAmh ? "ቅድመ ጥያቄ" : "Pre Request"}

                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "አጠቃላይ ኢንሹራንስ"
                            : "Efficient and Transparent Claims Handling: Guiding You Through the Process with Care and Expertise. Discover our commitment to providing a seamless claims experience, ensuring your peace of mind when you need it most."}
                    </p>
                </div>
            </div>
            {/*  */}

            {/* ORGANIZATIONAL STRUCTURE */}
            <div className="relative w-4/5 ml-[10%] px-3 mt-8">
                <div className="max-w-6xl mx-auto flex flex-col py-6  w-1/2 ml-1/4 ">
                    <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full ">

                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Our Pre Request and Claim Handing Service"}
                    </h1>

                </div>
            </div>
            <section className='w-4/5 ml-[10%] mb-32 px-16 mt-8'>
                <div className='flex items-stretch justify-center gap-x-10'>
                    <p className='w-1/2 leading-8 pt-8'>At NIB Insurance, we believe in making the claims process as smooth as possible for our valued policyholders. Our dedicated claims team is committed to providing you with prompt and efficient service. With clear communication and a focus on delivering fair and timely outcomes, we're here to support you during challenging times. Your peace of mind is our top priority

                        Choose NIB Insurance for a claims experience that's efficient, reliable, and hassle-free. We're your trusted partner, ensuring that when you need us most, we're here to help. Count on us to handle your claims with professionalism and care.
                    </p>
                    <div className='w-1/2 rounded-lg overflow-hidden'>
                        <img className='w-[90%]' src={prerequest2} alt="" />

                    </div>
                </div>

            </section>

            <section className='w-4/5 ml-[10%] mb-32 px-16 mt-8'>
                <div className='flex items-stretch justify-center gap-x-10'>
                    <div className='w-1/2 rounded-lg overflow-hidden'>
                        <img className='w-[90%]' src={prerequest3} alt="" />

                    </div>
                    <div className="max-w-6xl mx-auto flex flex-col py-6  w-1/2 ml-1/4 ">
                        <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full ">

                            {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "What makes our service unique"}
                        </h1>
                        <section>
                            <CheckList text={isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Timeous follow up of reported claims and those in process"} />
                            <CheckList text=
                                {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Adopt unique, form-fitted solutions and avoiding a one-size-fit-all approach"}
                            />
                            <CheckList text
                                ={isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Efficient claims processing using our optimized digital system/platform"}
                            />

                            <CheckList text=
                                {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Pay keen attention to all customer complaints and adhering to deadlines in claims handling"}
                            />
                            <CheckList text=
                                {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Adopt unique, form-fitted solutions and avoiding a one-size-fit-all approach"}
                            />

                        </section>


                    </div>


                </div>
            </section>

            <section className='border w-4/5 ml-[10%] mb-16 rounded-xl shadow-md'>
                <div className='flex flex-col items-center justify-center'>
                    <img src={prerequest4} alt="" className='w-20' />
                    <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full text-center">

                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Requirements from Customer at time of claims"}
                    </h1>
                </div>
                <div className='w-[70%] ml-[15%] mb-16'>
                    <CheckList text={isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Timeous follow up of reported claims and those in process.Timeous follow up of reported claims and those in process"} />
                    <CheckList text=
                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Adopt unique, form-fitted solutions and avoiding a one-size-fit-all approach.Adopt unique, form-fitted solutions and avoiding a one-size-fit-all approach"}
                    />
                    <CheckList text
                        ={isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Efficient claims processing using our optimized digital system/platform. Efficient claims processing using our optimized digital system/platform"}
                    />

                    <CheckList text=
                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Pay keen attention to all customer complaints and adhering to deadlines in claims handling. Pay keen attention to all customer complaints and adhering to deadlines in claims handling"}
                    />
                    <CheckList text=
                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Adopt unique, form-fitted solutions and avoiding a one-size-fit-all approach"}
                    />
                </div>
            </section>

            <section className='w-[70%] ml-[15%] mb-16'>
                <h1 className="font-medium  text-[#661F00] py-1 text-2xl  ">

                    {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Report within 24 hours!"}
                </h1>
                <div className='flex items-center gap-4 justify-center'>
                    <p className='py-1'>Your well-being matters to us, and we're here to support you every step of the way. In the event of an accident, it's essential to report it within 24 hours of the incident date. This timely reporting ensures that we can initiate the claims process promptly, assess the situation, and provide you with the necessary assistance. Your cooperation in reporting accidents promptly is a crucial step toward a swift resolution and your peace of mind.
                    </p>
                    <img className='w-28' src={prerequest5} alt="" />
                </div>
            </section>


        </div>
    )
}



export default PreRequest


/*







        <div className="pt-20">

            <div
                style={{
                    backgroundImage: `url(${prerequest})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />


            </div>
            <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                    {isAmh ? "ቅድመ ጥያቄ" : "Pre Request"}
                </h1>
                <p className="text-lg font-light text-white w-[40%]">
                    {isAmh
                        ? "አጠቃላይ ኢንሹራንስ"
                        : "Efficient and Transparent Claims Handling: Guiding You Through the Process with Care and Expertise. Discover our commitment to providing a seamless claims experience, ensuring your peace of mind when you need it most."}
                </p>
            </div>
            <div className="relative w-4/5 ml-[10%] px-3">
                <div className="max-w-6xl mx-auto flex flex-col py-6">
                    <h1 className="font-medium  text-[#661F00] py-1 text-2xl">

                        {isAmh ? "የዳይሬክተሮች ቦርድ" : "Board Of Directors"}
                    </h1>
                    <div className="border border-[#FFB300] w-28"></div>
                </div>
            </div>
        </div>









*/