
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import bid from "../../assets/bid.png"
import NewsCard from './components/NewsCard';
import { useNavigate } from 'react-router-dom';
import news from "../../assets/news2.png"
import news2 from "../../assets/news2.png"


const Bid = () => {

    const { isAmh } = useContext(LangContext);


    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${bid})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "" : "Bid"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Dive into our bid listings page, where we share valuable opportunities for partnerships and business engagement. From procurement bids to partnership calls, this is your gateway to explore and expand."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[11%] mb-16">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "" : "List of Bids"}
                    </h1>
                    <p className="my-2 ">
                        Bids available at our company, for interested bidders.
                    </p>
                </div>

            </div>


            <section className='w-4/5 ml-[11%] mb-64 flex flex-col items-stretch justify-center flex-wrap gap-12 '>
                {Array.from({ length: 8 }).map((item, index) => (
                    <NewsCardFull fullWidth />
                ))}
            </section>

        </div>
    )
}

const NewsCardFull = () => {

    const navigate = useNavigate()

    return (
        <article className='shadow-md flex items-center justify-center w-full mb-2 -lg overflow-hidden cursor-pointer'
            onClick={() => navigate("/news/1")}>
            <img className='h-[60%] w-1/4' src={news2} alt="" />
            <div>
                <h2 className=' pb-2 font-bold px-4'>20th Annual General Meeting of Shareholders</h2>
                <p className=' pb-2 px-4 text-sm'>We invite qualified suppliers to participate in our tender for the provision of comprehensive IT solutions to enhance and support our dynamic IT department. The scope of this tender encompasses a wide range of electronic goods and accessories tailored to meet the evolving needs of our IT infrastructure. We are seeking innovative and reliable partners who can provide top-quality hardware, software, and accessories necessary for the smooth functioning of our IT operations...</p>
                <button className='text-[#661F00] ml-4 text-sm'>Read More</button>
                <p className='w-full text-right px-4 text-sm'>Sep 21, 2023</p>

            </div>

        </article>
    )
}


export default Bid
