import executive from "../../assets/executive.png";
import directors from "../../assets/directors.png";
import message from "../../assets/message.png";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "react-query";
import { LangContext } from "../../context/LangContext";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Abouts from "../Home/components/About";
import bodyone from "../../assets/LeftFrame.png";
import bodytwo from "../../assets/RightFrame.png";
import BodyOne from "./components/BodyOne";
import bulb from "../../assets/bulb.png";
import bag from "../../assets/bag.png";
import hat from "../../assets/hat.png";

const ExecutiveManagment = () => {
  const navigate = useNavigate();
  const { isAmh } = useContext(LangContext);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
  };
  const chairmanMessageData = useQuery(
    ["chairmanMessageDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}home/chairman-messages`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      // enabled: !!token,
      onSuccess: (res) => { },
    }
  );
  const aboutDatas = useQuery(
    ["aboutDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}home/about`, {
        headers,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      // enabled: !!token,
      onSuccess: (res) => { },
    }
  );

  const managementData = useQuery(
    ["managementDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}home/managments`, {
        headers,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      // enabled: !!token,
      onSuccess: (res) => { },
    }
  );
  console.log(managementData?.data?.data);
  const orderTopManagment = managementData?.data?.data?.data?.filter(
    (item) => item.type === "Top Managment"
  );

  //directors
  const orderDirector = managementData?.data?.data?.data?.filter(
    (item) => item.type === "Director"
  );

  return (
    <div className="">
      <div
        style={{
          backgroundImage: `url(${executive})`,
          backgroundPosition: "center",
          width: "100%",
          minHeight: "600px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <div className="absolute inset-0 " />
        <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
          <h1 className="text-white text-left font-semiBold text-3xl py-5 md:text-6xl">
            {isAmh ? "አስፈፃሚ አስተዳደር" : "Executive Managements"}
          </h1>
          <p className="text-lg font-light text-white w-[40%]">
            {isAmh
              ? "ቤት/ስለ እኛ"
              : "Leadership at Its Best: Meet Our Executive Management Team. Explore the profiles of our distinguished Executive Management Team, a group of seasoned professionals steering our company towards success. Discover the individuals who shape our strategies, foster innovation, and ensure our commitment to excellence."}
          </p>
        </div>
      </div>
      {/* ORGANIZATIONAL STRUCTURE */}

      <div className="relative w-[90%] ml-[3%] ">
        <div className="max-w-6xl mx-auto flex flex-col py-6">
          <h1 className="font-medium  text-[#661F00] py-1 text-2xl">
            {isAmh ? "አስፈፃሚ አስተዳደር" : "Executive Managements"}
          </h1>
          <div className="border border-[#FFB300] w-28"></div>

        </div>
      </div>


      <div className=" p-3">
        <div className="absolute left-0 top-0 h-[87.7%]   z-40  hidden md:flex">
          <img src={bodyone} alt="" className="h-full object-contain  " />
        </div>

        <div className="absolute right-0 top-0  h-[87.7%]  hidden md:flex">
          <img src={bodytwo} alt="" className="h-full object-contain  " />
        </div>

        <div className="max-w-full mx-[4%]">
          {/* order one MANAGEMENT */}


          <div className="flex flex-wrap items-stretch justify-evenly w-[90%] ml-[5%]">
            {orderTopManagment?.map((item) => (
              <Comments member={item} />
            ))}
          </div>


        </div>
      </div>
    </div >
  );
};


const Comments = ({ member }) => {
  const { isAmh } = useContext(LangContext);
  const [showInfo, setShowInfo] = useState(false);

  return (<article className="w-1/4 flex items-start relative cursor-pointer" >
    <section className="flex items-center justify-center flex-col shadow-lg rounded-xl w-full py-4 border-t border-gray-100 mb-16 ml-[10%] h-[17rem]"
      onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)}>
      <img src={member.managment_photo} alt="" />
      <div className="w-full">
        <h5 className="font-bold px-4 text-left"> {isAmh ? member?.name?.amharic : member.name?.english}</h5>
        <p className="text-[#60A5FA] px-4 text-left">{isAmh ? member?.role?.amharic : member.role?.english}</p>
      </div>
    </section>

    {showInfo &&
      <article className="w-full top-[-8rem] left-[-2rem] absolute" >
        <div className=" w-8 absolute bottom-[-5px] ml-36 text-[#FFB300] z-0">
          <svg width="54" height="78" viewBox="0 0 54 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6408 77.4463L0.105438 0.0106659H53.1762L26.6408 77.4463Z" fill="#FFB300" />
          </svg>
        </div>
        <section className="relative border-4 border-[#FFB300] rounded-3xl py-4  px-2 ml-16 mb-8 min-w-[26rem] z-10 bg-white">
          <div className="flex items-start justify-center mb-2 text-sm gap-x-1">
            <img className="w-5" src={hat} alt="" />
            <p className="w-1/3 text-[#661F00] ml-2 font-semibold">{isAmh ? "" : "Qualification:"}</p>
            <p className="w-2/3">{isAmh ? "" : "MBS, in Political Science and International Relations"}</p>
          </div>
          <div className="flex items-start justify-center mb-2 text-sm gap-x-1">
            <img className="w-4" src={bag} alt="" />
            <p className="w-1/3 text-[#661F00] ml-2 font-semibold">{isAmh ? "" : "Work Experience:"}</p>
            <p className="w-2/3">{isAmh ? "" : "30+ Years"}</p>
          </div>
          <div className="flex items-start justify-center text-sm gap-x-1">
            <img className="w-4" src={bulb} alt="" />
            <p className="w-1/3 text-[#661F00] ml-2 font-semibold">{isAmh ? "" : "Life Philosophy:"}</p>
            <p className="w-2/3">{isAmh ? "" : '\"Embrace each moment with gratitude and kindness.\"'}</p>
          </div>
        </section>

      </article>
    }
  </article >)
}
export default ExecutiveManagment;
