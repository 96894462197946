
import React, { useContext } from 'react'
import agm from "../../assets/agm.png"
import { LangContext } from '../../context/LangContext';
import NewsCard from './components/NewsCard';
const AGM = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${agm})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "ዓመታዊ ጠቅላላ ጉባኤ" : "Annual General Meeting"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Dive into our comprehensive coverage of our Annual General Meetings, where we share key announcements, insights, and achievements. This is your gateway to the latest news, strategic updates, and a deeper understanding of our company's direction."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[10%] mb-16">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "አማስታወቂያዎች እና ዜናዎች" : "AGM Announcements and News"}
                    </h1>
                    <p className="my-2 ">
                        Get updates of AGM announcements and information about our annual general meetings.
                    </p>
                </div>

            </div>

            <section className='w-4/5 ml-[10%] flex items-stretch justify-center flex-wrap gap-12 mb-64'>
                {Array.from({ length: 8 }).map((item, index) => (
                    <NewsCard />
                ))}
            </section>


        </div>
    )
}

export default AGM
