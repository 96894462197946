
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import gallery from "../../assets/gallery.png"
import NewsCard from './components/NewsCard';
import news from "../../assets/news2.png"
const Gallery = () => {

    const { isAmh } = useContext(LangContext);

    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${gallery})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "ዓመታዊ ጠቅላላ ጉባኤ" : "gallery"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Dive into our comprehensive coverage of our Annual General Meetings, where we share key announcements, insights, and achievements. This is your gateway to the latest news, strategic updates, and a deeper understanding of our company's direction."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[10%] mb-16">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "አማስታወቂያዎች እና ዜናዎች" : "Gallery of our activities"}
                    </h1>
                    <p className="my-2 ">
                        Explore our photo galleries of company events and activities.
                    </p>
                </div>

            </div>

            <section className='w-4/5 ml-[10%] flex items-stretch justify-center flex-wrap gap-8 mb-64'>
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />
                <img className='w-[22%] rounded-md ' src={news} alt="" />





            </section>


        </div>
    )
}

export default Gallery
