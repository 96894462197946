
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import tracking from "../../assets/tracking.png"
import calander from "../../assets/calander.png"

const Tracking = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div className="">
            <div
                style={{
                    backgroundImage: `url(${tracking})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl ">
                        {isAmh ? "ቅድመ ጥያቄ" : "Tracking"}

                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "አጠቃላይ ኢንሹራንስ"
                            : "Track and Monitor Your Claims. We put you in the driver's seat of your insurance experience. Explore the status and details of your claims in a clear and organized table, ensuring transparency and peace of mind. Your insurance journey is now at your fingertips, and we're here to make it effortless."}
                    </p>
                </div>
            </div>
            {/*  */}

            {/* ORGANIZATIONAL STRUCTURE */}
            <div className="relative  px-3 mt-8">
                <div className="max-w-6xl mx-auto py-6 ">
                    <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full ">

                        {isAmh ? "የቅድመ ጥያቄ እና የይገባኛል ጥያቄ አሰጣጥ አገልግሎት" : "Your Claims"}
                    </h1>
                    <p>Check out your list of Claims, their status, and details, and contact us if you have inquiries.</p>
                </div>
            </div>

            <section className='w-4/5 ml-[10%] mb-16 '>
                <section className='bg-[#F7F4F2] text-[#9F725F] rounded-md flex items-center justify-center py-2 w-full text-center'  >
                    <div className='border-l w-[10%]'>N.o</div>
                    <div className='border-l w-[15%]'>Date Of Application</div>
                    <div className='border-l w-[15%]'>Claim ID</div>
                    <div className='border-l w-[15%]'>Calim Type</div>
                    <div className='border-l w-[15%]'>Calim Status</div>
                    <div className='border-l w-[30%]'>Claims Details</div>
                </section>

                {Array.from({ length: 6 }).map((item, index) => (
                    <ClaimsItem key={index + "--" + index} item={
                        { no: ++index, date: "May 23,2023", id: "871322-23929", type: "Life Insurance", status: "Pending", details: "Solomon Taye's Auto Insurance Claim - Date: 2023-09-15.Description: Claim for vehicle damage following an accident." }
                    } />
                ))}
            </section>


            <section className='mb-36  flex items-center justify-center gap-x-6 w-2/5 ml-[30%]'>
                <p className='text-sm text-gray-300'>Prev</p>

                {Array.from({ length: 5 }).map((item, index) => (
                    <Counter key={index + "-''-" + index} counter={++index} />
                ))}
                <p className='text-[#661F00] '>...</p>
                <Counter counter={6} />

                <p className='text-sm text-[#661F00] font-bold '>Next</p>

            </section>



        </div>
    )
}

const ClaimsItem = ({ item }) => {

    return (<section className=' rounded-lg my-4 bg-[#F7F4F2]'>
        <section className='flex items-center justify-center py-2 w-full text-center'  >
            <div className='border-l w-[10%]'>{item.no}.</div>
            <div className='border-l w-[15%] flex items-center justify-center gap-x-2'>
                <img className='w-4' src={calander} alt="" />
                <p>{item.date}</p></div>
            <div className='border-l w-[15%]'>{item.id}</div>
            <div className='border-l w-[15%]'>{item.type}</div>
            <div className='border-l w-[15%] '>
                <p className='mx-8  rounded-full py-1 bg-[#F0DFAB] text-sm text-[#DEAE04] font-bold'>{item.status}</p></div>
            <div className='border-l w-[30%] text-sm p-4'>{item.details}</div>
        </section>
    </section>)
}

const Counter = ({ counter }) => {
    return (<div className={counter === 1 ? "cursor-pointer border border-gray-200 bg-[#661F00] text-white text-sm py-1 px-2 rounded-lg" :
        "cursor-pointer border border-gray-200 text-[#661F00] text-sm py-1 px-2 rounded-lg"}>
        {counter}
    </div >)
}
export default Tracking







