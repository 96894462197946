const Form = () => {
    return (<article className='w-2/5 ml-[30%] mb-36'>
        <p className='text-sm mt-6'>Full Name</p>
        <div className='flex items-center justify-center gap-x-4'>
            <input className="border border-[#9F725F] w-1/2 rounded-lg py-2 px-6" type="text" placeholder="John" />
            <input className="border border-[#9F725F] w-1/2 rounded-lg py-2 px-6" type="text" placeholder="Doe" />
        </div>
        <p className='text-sm mt-6'>Phone number</p>
        <div className='flex items-center justify-center border border-[#9F725F] w-full rounded-lg py-1 '>
            <p className="w-[15%] my-1 border-r border-r-[#9F725F] text-center" >+215</p>
            <input className="w-[85%] px-4" type="text" placeholder="913-658-927" />
        </div>
        <p className='text-sm mt-6'>Location</p>
        <div className='flex items-center justify-center border border-[#9F725F] rounded-lg py-2 px-4'>
            <input className="w-[95%]" type="text" placeholder="Bole, Addis Ababa" />
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6663 1.5L5.99967 6.16667L1.33301 1.5" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
        <div className='flex items-center justify-center gap-x-4'>
            <div className='w-1/2'>
                <p className='text-sm mt-6'>Date</p>
                <input className="border border-[#9F725F] w-full rounded-lg py-2 px-4" type="date" placeholder="DD-MM-YYYY" />
            </div>
            <div className='w-1/2'>
                <p className='text-sm mt-6'>Time</p>
                <input className="border border-[#9F725F] w-full rounded-lg py-2 px-4" type="time" placeholder="00:00 AM" />
            </div>
        </div>
        <p className='text-sm mt-6'>Attach your policy</p>
        <div className='flex items-center justify-center border border-[#9F725F] rounded-lg py-2 px-4'>
            <input className="w-[95%]" type="file" placeholder="mypolicy.pdf" />
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7497 5.00004V14.5834C13.7497 16.425 12.258 17.9167 10.4163 17.9167C8.57467 17.9167 7.08301 16.425 7.08301 14.5834V4.16671C7.08301 3.61417 7.3025 3.08427 7.6932 2.69357C8.0839 2.30287 8.61381 2.08337 9.16634 2.08337C9.71888 2.08337 10.2488 2.30287 10.6395 2.69357C11.0302 3.08427 11.2497 3.61417 11.2497 4.16671V12.9167C11.2497 13.375 10.8747 13.75 10.4163 13.75C9.95801 13.75 9.58301 13.375 9.58301 12.9167V5.00004H8.33301V12.9167C8.33301 13.4692 8.5525 13.9991 8.9432 14.3898C9.3339 14.7805 9.86381 15 10.4163 15C10.9689 15 11.4988 14.7805 11.8895 14.3898C12.2802 13.9991 12.4997 13.4692 12.4997 12.9167V4.16671C12.4997 2.32504 11.008 0.833374 9.16634 0.833374C7.32467 0.833374 5.83301 2.32504 5.83301 4.16671V14.5834C5.83301 17.1167 7.88301 19.1667 10.4163 19.1667C12.9497 19.1667 14.9997 17.1167 14.9997 14.5834V5.00004H13.7497Z" fill="black" fill-opacity="0.5" />
            </svg>

        </div>
        <p className='text-sm mt-6'>Describe your case</p>
        <textarea rows={8} className="border-2 border-[#9F725F] w-full rounded-lg py-2 px-4" type="text" placeholder="Tell us about your claim in short..." />

        <div className='w-full flex items-center justify-center mt-10'>
            <button className='px-16 py-2 bg-[#FFB300] text-white font-bold rounded-md'>Submit Claim</button>
        </div>

    </article>)
}

export default Form