
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import freport from "../../assets/freport.png"
import PdfFile from '../../components/PdfFile';

const FinancialReport = () => {

    const { isAmh } = useContext(LangContext);


    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${freport})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "" : "Financial Reports"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Unlocking Financial Insights: Explore Our Reports and Documents. At NIB Insurance, we believe in transparency and accountability. Dive into our comprehensive collection of financial reports and essential company documents. We're committed to keeping you informed."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[11%] mb-16">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "አማስታወቂያዎች እና ዜናዎች" : "Financial reports and Documents"}
                    </h1>
                    <p className="my-2 ">
                        Look into the documents to find all of the company's reports and information.
                    </p>
                </div>

            </div>

            <article className='flex flex-wrap justify-center w-4/5 ml-[10%]  mt-2 gap-4 mb-64'>
                {Array.from({ length: 14 }).map((item, index) => (
                    <PdfFile key={index + "12212.pdf"} />
                ))}
            </article>


        </div>
    )
}

export default FinancialReport
