
import React from 'react'
import news from "../../assets/news2.png"
import NewsCard from './components/NewsCard'

const NewsDetails = () => {
    return (
        <article className=' py-8 flex items-start justify-center gap-x-8 px-[5%] mb-16'>
            <section className='w-2/3 mt-36'>
                <img className='w-full' src={news} alt="" />
                <p className='font-bold text-2xl py-4'>20th Annual General Meeting of Shareholders, Highlights from the Meeting at Sheraton Addis Hotel</p>
                <div className='flex items-center justify-between text-[#661F00] w-full '>
                    <p className='font-bold text-sm'>By NIB Insurance</p>
                    <p>3 days ago</p>
                </div>

                <p className='font-bold mt-8 mb-4'>"Bringing Shareholders Together” - A Recap of Our 20th Annual General Meeting</p>
                <p className='leading-8'>In a momentous gathering of stakeholders, our 20th Annual General Meeting of Shareholders took center stage at the prestigious Sheraton Addis Hotel. This annual tradition marked not only a celebration of two decades of excellence but also an opportunity to engage with our valued shareholders.
                    The event was an embodiment of our commitment to transparency and accountability. We shared key insights into our financial performance, highlighted major milestones, and unveiled our vision for the future. Our leadership team presented a comprehensive review of the year's achievements and unveiled strategic plans for the coming year.
                    One of the meeting's highlights was the opportunity for shareholders to directly interact with our executive team and voice their opinions, strengthening the sense of partnership and community. We also took time to recognize outstanding contributions from our dedicated shareholders and employees who have played pivotal roles in our journey.
                    At NIB Insurance, we believe that open dialogue and collaboration with our shareholders are fundamental to our continued growth and success. We extend our heartfelt gratitude to all who joined us at this historic event and look forward to a promising future together.
                    Stay tuned for more detailed updates and insights from the 20th Annual General Meeting as we continue to provide you with transparency and valuable information.</p>
            </section>
            <section className='w-1/3 flex flex-col items-center justify-center mt-36'>
                <h4 className='font-bold text-[#661F00] w-[90%] ml-[8%] text-xl pb-4'>Other News</h4>
                <NewsCard style />
                <NewsCard style />

            </section>
        </article>
    )
}

export default NewsDetails
