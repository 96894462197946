import React from "react";
import { LangContext } from "../../../context/LangContext";
import { useContext } from "react";

import motor from "../../../assets/motor.png";
import Form from "../../../components/Form";

const MotorThirdParty = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${motor})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "ሞተር ሶስተኛ ወገን" : "Motor Third Party"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Explore our straightforward application process, designed to make securing your coverage hassle-free. Whether you're a policyholder or looking to protect against third-party liabilities, we've got you covered. Your safety and convenience are our top priorities, and we're dedicated to providing efficient solutions for all your motor insurance needs."}
                    </p>
                </div>
            </div>
            <div className="py-2 mx-44">
                <div className="">
                    <h1 className="font-medium text-xl pt-2 text-[#661F00] w-full text-center">
                        {isAmh ? "ሞተር ሶስተኛ ወገን" : "Motor Third Party"}
                    </h1>
                    <p className="my-2 text-center w-full">
                        Fill out the forms below to receive our Motor Third Party service. And start processing where you are.
                    </p>
                </div>
            </div>

            <Form />
        </div>
    );
};

export default MotorThirdParty;
