

import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import claimNoti from "../../assets/claimNotic.png"
import Form from '../../components/Form';
import PdfFile from '../../components/PdfFile';

const ClaimsNotification = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div className="">
            <div
                style={{
                    backgroundImage: `url(${claimNoti})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl ">
                        {isAmh ? "ይገባኛል ጥያቄዎች ማሳወቂያዎች" : "Claims Notifications"}

                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "አጠቃላይ ኢንሹራንስ"
                            : "Explore our collection of downloadable claim documents, making it easy to initiate your claims. Additionally, we offer the convenience of applying online, ensuring that your claims are just a few clicks away. Your peace of mind is our priority, and we're here to provide you with efficient and accessible solutions."}
                    </p>
                </div>
            </div>
            {/*  */}

            {/* ORGANIZATIONAL STRUCTURE */}
            <div className="relative  px-3 mt-8">
                <div className="max-w-6xl mx-auto py-6 ">
                    <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full ">

                        {isAmh ? "የይገባኛል ጥያቄ ማሳወቂያ ቅጾች" : "Claim Notification Forms"}
                    </h1>
                    <p>{isAmh ? "ሊወርዱ የሚችሉ ቅጾች፡ የመጀመር ሂደቱን ቀላል ማድረግ።" : "Downloadable Forms: Simplifying the Process of Getting Started."}</p>
                </div>
            </div>


            <article className='flex flex-wrap justify-center w-4/5 ml-[10%]  mt-2 gap-4 mb-24'>
                {Array.from({ length: 10 }).map((item, index) => (
                    <PdfFile key={index + "12212.pdf"} />
                ))}
            </article>

            <article>
                <h1 className="font-medium  text-[#661F00] py-1 text-2xl w-full text-center ">

                    {isAmh ? "የይገኛል ጥያቄዎችዎን በመስመር ላይ ያሳውቁ" : "Notify Your Claims Online"}
                </h1>
                <p className='w-full text-center'>You can easily apply your claims by filling out the forms below. Continue filling out the necessary information below.</p>
            </article>

            <Form />

        </div>
    )
}






export default ClaimsNotification




















