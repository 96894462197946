
import React from 'react'

const Vacancies = () => {
    return (
        <article className='mb-8 w-full rounded-lg shadow-md flex flex-col items-start justify-center p-8'>
            <section className='w-full flex items-center justify-between'>
                <div className='mb-3'>
                    <h3 className='font-bold text-md '>Human Affairs & Business Developments</h3>
                    <p className='font-bold text-md text-[#661F00]'>Expert Level</p>
                </div>
                <div>
                    <button className='bg-[#E0D2CC] text-[#661F00] font-bold rounded-full px-4 py-1 text-sm'>Fulltime</button>
                </div>
            </section>
            <p className='pr-6'>We currently have an exciting vacancy for an experienced Human Affairs & Business Developments Expert at NIB Insurance. As an Expert in this field, you will play a crucial role in driving our growth and fostering a positive work environment. Your responsibilities will encompass developing and implementing strategic business development plans, nurturing relationships with clients and stakeholders, overseeing human affairs and HR-related functions, analyzing market trends, and contributing to policy and strategy development. If you are an expert in business development and human affairs with a passion for driving positive change and .........</p>

            <button className='mt-4 px-10 py-1 rounded-lg font-bold text-[#661F00] border border-[#661F00]'>Details</button>

        </article>
    )
}

export default Vacancies
