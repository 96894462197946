
import React, { useContext } from 'react'
import vacancy from "../../assets/vacancy.png"
import { LangContext } from '../../context/LangContext';
import Vacancies from './components/Vacancies';
const Vacancy = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${vacancy})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "" : "Vacancy"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Unlock Your Career Potential: Explore Our Job Openings. We believe in nurturing talent and fostering growth. Dive into our vacancy page, where we showcase exciting job opportunities. From dynamic roles to career advancement, this is your gateway to explore and elevate your career."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[11%] mb-64">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "" : "Our Mission"}
                    </h1>
                    <p className="my-2 ">
                        Vacancies available
                    </p>
                </div>


                {Array.from({ length: 5 }).map((item, index) => (
                    <Vacancies key={index + "--"} />
                ))}

            </div>

        </div>
    )
}

export default Vacancy
