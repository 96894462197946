
import React, { useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import media from "../../assets/media.png"
import NewsCard from './components/NewsCard';

const Events = () => {

    const { isAmh } = useContext(LangContext);


    return (
        <div>
            {" "}
            <div
                style={{
                    backgroundImage: `url(${media})`,
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: "800px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <div className="absolute inset-0 " />
                <div className="flex flex-col items-left absolute w-full text-left  justify-start z-30 top-1/3 pl-44">
                    <h1 className="text-white text-left font-semiBold text-2xl py-5 md:text-6xl">
                        {isAmh ? "ዓመታዊ ጠቅላላ ጉባኤ" : "Events"}
                    </h1>
                    <p className="text-lg font-light text-white w-[40%]">
                        {isAmh
                            ? "ቤት/ስለ እኛ"
                            : "Celebrate With Us: Explore Our Company Events. Discover moments of connection, learning, and achievement on our events page. Join us in celebrating milestones and engaging with our community."}
                    </p>
                </div>
            </div>
            <div className=" w-4/5 ml-[11%] mb-16">
                <div className="">
                    <h1 className="font-medium text-xl text-[#661F00] ">
                        {isAmh ? "አማስታወቂያዎች እና ዜናዎች" : "Our Company Events and Engagments"}
                    </h1>
                    <p className="my-2 ">
                        Discover the company's events, accomplishments, and activities.
                    </p>
                </div>

            </div>

            <h1 className="font-medium text-xl text-[#661F00] w-4/5 ml-[11%] mb-6">
                {isAmh ? "አ" : "Recent Events"}
            </h1>
            <section className='w-4/5 ml-[10%] flex items-stretch justify-center flex-wrap gap-12 mb-20'>

                {Array.from({ length: 3 }).map((item, index) => (
                    <NewsCard />
                ))}
            </section>

            <h1 className="font-medium text-xl text-[#661F00] w-4/5 ml-[11%] mb-6">
                {isAmh ? "አ" : "Others"}
            </h1>
            <section className='w-4/5 ml-[10%] flex items-stretch justify-center flex-wrap gap-12 mb-64'>

                {Array.from({ length: 3 }).map((item, index) => (
                    <NewsCard />
                ))}
            </section>


        </div>
    )
}

export default Events
