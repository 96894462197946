const PdfFile = () => {
    return (<section className='w-[22%] flex flex-col items-center justify-center border py-6 shadow-md'>
        <svg width="85" height="73" viewBox="0 0 85 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_531_12259)">
                <path d="M29.333 47.0552C30.0738 46.6723 30.8834 46.2988 31.7618 45.944C31.0198 46.9201 30.2251 47.8643 29.3806 48.7733C27.899 50.3468 26.7454 51.1825 26.0205 51.444C25.9603 51.4664 25.8984 51.4851 25.8353 51.5C25.7825 51.4353 25.7364 51.3666 25.6977 51.2946C25.4014 50.781 25.412 50.2861 25.9094 49.6138C26.4703 48.8434 27.5974 47.9609 29.333 47.0552ZM42.3237 39.3654C41.694 39.4822 41.0696 39.5989 40.4399 39.7296C41.3729 38.1173 42.2551 36.4826 43.0857 34.8272C43.9217 36.1951 44.822 37.5316 45.7844 38.8332C44.6361 38.9826 43.4773 39.16 42.3237 39.3654ZM55.6848 43.7496C54.863 43.1645 54.0935 42.5246 53.383 41.8353C54.5895 41.8587 55.6795 41.938 56.6214 42.0874C58.2988 42.3536 59.0873 42.7738 59.3624 43.0632C59.4492 43.1441 59.4983 43.2507 59.5 43.3621C59.4815 43.6914 59.3725 44.0119 59.1825 44.2958C59.0758 44.5233 58.9045 44.7227 58.6851 44.8748C58.5759 44.9339 58.4472 44.9586 58.32 44.9448C57.8438 44.9308 56.9548 44.6367 55.6848 43.7496ZM43.9112 21.5908C43.6995 22.73 43.3397 24.0373 42.8529 25.4614C42.6735 24.9282 42.5164 24.3893 42.3819 23.8459C41.9797 22.1978 41.9215 20.9045 42.1385 20.0081C42.3396 19.1817 42.7206 18.8502 43.1756 18.6868C43.4197 18.5918 43.6782 18.5289 43.9429 18.5C44.0117 18.6401 44.0911 18.9295 44.1122 19.4244C44.1387 19.9941 44.0752 20.7177 43.9112 21.5955V21.5908Z" fill="#EF4444" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 0.5H63.5C66.2848 0.5 68.9555 1.44821 70.9246 3.13604C72.8937 4.82387 74 7.11305 74 9.5V63.5C74 65.8869 72.8937 68.1761 70.9246 69.864C68.9555 71.5518 66.2848 72.5 63.5 72.5H21.5C18.7152 72.5 16.0445 71.5518 14.0754 69.864C12.1062 68.1761 11 65.8869 11 63.5V9.5C11 7.11305 12.1062 4.82387 14.0754 3.13604C16.0445 1.44821 18.7152 0.5 21.5 0.5ZM22.3663 53.006C22.8387 53.816 23.5738 54.5495 24.6658 54.8915C25.7525 55.229 26.8288 55.0715 27.7108 54.7565C29.3803 54.1715 31.0445 52.7945 32.5723 51.2195C34.3205 49.415 36.158 47.048 37.9325 44.4245C41.3582 43.554 44.8664 42.9426 48.4167 42.5975C49.9917 44.321 51.6192 45.806 53.1942 46.8725C54.6642 47.8625 56.36 48.686 58.0977 48.749C59.0443 48.7892 59.9815 48.5718 60.7752 48.128C61.589 47.6735 62.1927 47.0165 62.6337 46.256C63.1062 45.4415 63.395 44.591 63.3582 43.7225C63.325 42.8662 62.955 42.0448 62.3082 41.3915C61.1217 40.1765 59.1792 39.5915 57.2682 39.299C54.9501 38.9912 52.598 38.9157 50.2595 39.074C48.2847 36.6811 46.5629 34.1421 45.1145 31.487C46.427 28.517 47.4087 25.709 47.8445 23.414C48.0335 22.433 48.1332 21.497 48.0965 20.651C48.09 19.8112 47.862 18.9836 47.4297 18.23C47.1807 17.8141 46.8267 17.4509 46.3936 17.1668C45.9604 16.8827 45.459 16.6848 44.9255 16.5875C43.865 16.394 42.773 16.5875 41.7702 16.934C39.791 17.609 38.7462 19.049 38.3525 20.6375C37.9692 22.1675 38.1425 23.9495 38.594 25.7495C39.056 27.5765 39.8435 29.5655 40.8515 31.577C39.239 35.014 37.3769 38.3611 35.276 41.5985C32.5704 42.3282 29.9624 43.3011 27.4955 44.501C25.553 45.491 23.8257 46.661 22.7862 48.0425C21.6837 49.5095 21.3425 51.2555 22.3663 53.006Z" fill="#EF4444" />
            </g>
            <defs>
                <clipPath id="clip0_531_12259">
                    <rect width="84" height="72" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
        <p>Bond Proposal Forms</p>
        <button className='bg-[#A27128] text-white text-sm py-1 px-4 rounded-md'>Download</button>
    </section>)
}

export default PdfFile