
import React, { useContext } from 'react'
import { LangContext } from '../../../context/LangContext';

const tempObj = [{
    name: "Eng. Girma Solomon",
    title: "Chairman"
},
{
    name: "Eng. Girma Solomon",
    title: "Chairman"
},
{
    name: "Eng. Girma Solomon",
    title: "Chairman"
},
{
    name: "Eng. Girma Solomon",
    title: "Chairman"
},
{
    name: "Eng. Girma Solomon",
    title: "Chairman"
}]
const Commitee = () => {
    const { isAmh } = useContext(LangContext);

    return (
        <article className='w-4/5 ml-[10%] mb-24 mt-4'>
            <section className="relative px-3 ">
                <div className="max-w-6xl mx-auto flex flex-col pb-6">
                    <h1 className="font-medium  text-[#661F00]  text-xl">
                        {isAmh ? "የቦርድ ኮሚቴዎች" : "Board Committees"}
                    </h1>
                    <div className="border border-[#FFB300] w-28"></div>
                </div>
            </section>

            <section className='flex items-stretch justify-center  gap-x-8'>
                <CommiteBox title="Audit Board Committee" CommiteeList={tempObj} />
                <CommiteBox title="Risk Management & Compliance Board Committee" CommiteeList={tempObj} />
                <CommiteBox title="HR Affairs Board Committee" CommiteeList={tempObj} />
                <CommiteBox title="Business Dev't and Strategy Board Committee" CommiteeList={tempObj} />

            </section>
        </article>
    )
}

const CommiteBox = ({ title, CommiteeList }) => {
    return (<article className='w-1/4 shadow-md border-t border-gray-100 rounded-md py-4'>
        <h2 className='py-4 px-2 text-center text-[#661F00] font-medium'>{title}</h2>
        <section className='text-center text-sm'>
            {CommiteeList.map((item, index) => (
                <div key={index + item.name} className='mb-4'>
                    <p>{item.name}</p>
                    <p>({item.title})</p>
                </div>
            ))}
        </section>
    </article>)
}

export default Commitee
