import { useContext } from "react";
import { LangContext } from "../context/LangContext";

const CheckList = ({ text, noMargin }) => {
    const { isAmh } = useContext(LangContext);

    return (<div className={noMargin ? "flex items-start justify-start gap-x-4 text-sm" : 'flex items-start justify-start gap-x-4 text-sm mt-6'}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10.71 16.29C10.6175 16.3827 10.5076 16.4563 10.3866 16.5064C10.2657 16.5566 10.136 16.5824 10.005 16.5824C9.87403 16.5824 9.74435 16.5566 9.62338 16.5064C9.5024 16.4563 9.39251 16.3827 9.3 16.29L5.71 12.7C5.61742 12.6074 5.54398 12.4975 5.49387 12.3765C5.44377 12.2556 5.41798 12.1259 5.41798 11.995C5.41798 11.8641 5.44377 11.7344 5.49387 11.6135C5.54398 11.4925 5.61742 11.3826 5.71 11.29C5.80258 11.1974 5.91249 11.124 6.03346 11.0739C6.15442 11.0238 6.28407 10.998 6.415 10.998C6.54593 10.998 6.67558 11.0238 6.79654 11.0739C6.91751 11.124 7.02742 11.1974 7.12 11.29L10 14.17L16.88 7.29C17.067 7.10302 17.3206 6.99798 17.585 6.99798C17.8494 6.99798 18.103 7.10302 18.29 7.29C18.477 7.47698 18.582 7.73057 18.582 7.995C18.582 8.25943 18.477 8.51302 18.29 8.7L10.71 16.29Z" fill="#FDB913" />
        </svg>
        <p>
            {text}
        </p>
    </div>)
}

export default CheckList;