import React from 'react'
import news from "../../../assets/news2.png"
import { useNavigate } from 'react-router-dom'

const NewsCard = ({ style }) => {
    const navigate = useNavigate()
    return (
        <article className={style ? 'w-[90%] mb-10 h-[25rem] rounded-lg shadow-md overflow-hidden cursor-pointer'
            : 'w-[30%] h-[30rem] rounded-lg shadow-md overflow-hidden cursor-pointer'}
            onClick={() => navigate("/news/1")}>
            <img className='h-[50%] w-full' src={news} alt="" />
            <h2 className=' pb-2 font-bold px-4'>20th Annual General Meeting of Shareholders</h2>
            <p className=' pb-2 px-4 text-sm'>Lorem ipsum dolor sit amet consectetur. Proin sagittis lacus mollis mi augue elementum in purus. Donec pharetra est at amet cras blandit velit. Fermentum eget morbi sed a aliquam....</p>
            <button className='text-[#661F00] ml-4 text-sm'>Read More</button>
            <p className='w-full text-right px-4 text-sm'>Sep 21, 2023</p>

        </article>
    )
}

export default NewsCard
